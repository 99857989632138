import axios from 'axios'
import config from "../config"

const interceptor = axios.create({
  baseURL: config.apiEndpoint,
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
})

axios.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default interceptor
